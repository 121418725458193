<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-row>
      <!-- BEGIN:Table -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Detail Data IA</h4>
          </template>
          <template v-slot:body>
            <div class="d-inline-block float-right">
              <router-link
                class="btn btn-secondary dark-btn-primary"
                :to="{ name: 'kerjasama.jenisDokumen' }"
                role="button"
                ><i class="ri-arrow-left-line"></i>Kembali</router-link
              >
            </div>
            <div class="align-items-center mt-lg-5">
              <b-form>
                <b-form-group label="Kode IA:" label-for="kodeIa">
                  <input-primary
                    id="kodeIa"
                    v-model="form.kodeIa"
                    required
                    max-length="100"
                    isDetail
                  />
                </b-form-group>
                <b-form-group>
                  <label for="nomorSuratUt">Nomor Surat UT:</label
                  ><span class="text-danger ml-1">*</span>
                  <input-primary
                    id="nomorSuratUt"
                    v-model="form.nomorSuratUt"
                    required
                    placeholder="Nomor Surat UT"
                    max-length="100"
                    disabled
                    isDetail
                  />
                </b-form-group>
                <b-form-group>
                  <label for="nomorSuratMitra">Nomor Surat Mitra:</label
                  ><span class="text-danger ml-1"></span>
                  <input-primary
                    id="nomorSuratMitra"
                    v-model="form.nomorSuratMitra"
                    placeholder="Nomor Surat Mitra"
                    max-length="100"
                    disabled
                    isDetail
                  />
                </b-form-group>
                <b-form-group>
                  <label for="tanggalSurat">Tanggal Surat:</label
                  ><span class="text-danger ml-1">*</span>
                  <input-primary
                    id="tanggalSurat"
                    ref="refTanggalSurat"
                    :config="basicConfig"
                    v-model="form.tanggalSurat"
                    placeholder="Tanggal Surat"
                    disabled
                    isDetail
                  />
                </b-form-group>
                <b-row class="mt-4">
                  <!--  PIHAK PERTAMA -->
                  <b-col>
                    <h4 class="mb-2">PIHAK PERTAMA</h4>
                    <b-form-group>
                      <label for="namaPihakSatu">Nama Pihak Pertama:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="namaPihakSatu"
                        v-model="form.namaPihakSatu"
                        placeholder="Nama Pihak Pertama"
                        disabled
                        isDetail
                        max-length="100"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="jabatanPihakSatu">Jabatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="jabatanPihakSatu"
                        v-model="form.jabatanPihakSatu"
                        placeholder="Jabatan"
                        disabled
                        isDetail
                        max-length="100"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="instansiPihakSatu">Instansi:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="instansiPihakSatu"
                        v-model="form.instansiPihakSatu"
                        placeholder="Instansi"
                        disabled
                        isDetail
                        max-length="100"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="alamatPihakSatu">Alamat:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="alamatPihakSatu"
                        v-model="form.alamatPihakSatu"
                        disabled
                        isDetail
                        type="textarea"
                        max-length="255"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- PIHAK KEDUA -->
                  <b-col>
                    <h4 class="mb-2">PIHAK KEDUA</h4>
                    <b-form-group>
                      <label for="namaPihakDua">Nama Pihak Kedua:</label
                      ><span class="text-danger ml-1"></span>
                      <input-primary
                        id="namaPihakDua"
                        v-model="form.namaPihakDua"
                        placeholder="Nama Pihak Kedua"
                        max-length="100"
                        disabled
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="jabatanPihakDua">Jabatan:</label
                      ><span class="text-danger ml-1"></span>
                      <input-primary
                        id="jabatanPihakDua"
                        v-model="form.jabatanPihakDua"
                        placeholder="Jabatan"
                        max-length="100"
                        disabled
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="instansiPihakDua">Instansi:</label
                      ><span class="text-danger ml-1"></span>
                      <input-primary
                        id="instansiPihakDua"
                        v-model="form.instansiPihakDua"
                        placeholder="Instansi"
                        max-length="100"
                        disabled
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="alamatPihakDua">Alamat:</label
                      ><span class="text-danger ml-1"></span>
                      <input-primary
                        id="alamatPihakDua"
                        v-model="form.alamatPihakDua"
                        disabled
                        isDetail
                        max-length="255"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <!-- ---- -->
                </b-row>
                <b-row class="mt-4">
                  <!-- KEGIATAN -->
                  <b-col>
                    <h4 class="mb-2">KEGIATAN</h4>
                    <b-form-group>
                      <label for="namaKegiatan">Nama Kegiatan:</label
                      ><span class="text-danger ml-1"></span>
                      <input-primary
                        id="namaKegiatan"
                        v-model="form.namaKegiatan"
                        disabled
                        isDetail
                        placeholder="Nama Kegiatan"
                        max-length="255"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="judulPks">Judul Kegiatan:</label
                      ><span class="text-danger ml-1"></span>
                      <input-primary
                        id="judulPks"
                        v-model="form.judulPks"
                        placeholder="Judul Kegiatan"
                        max-length="255"
                        disabled
                        isDetail
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <label for="tanggalAcara">Tangga Mulai Kegiatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="tanggalAcara"
                        v-model="form.tanggalAcara"
                        placeholder="Tangga Mulai Kegiatan"
                        disabled
                        isDetail
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group>
                      <label for="tanggalAkhirAcara"
                        >Tanggal Selesai Kegiatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="tanggalAkhirAcara"
                        v-model="form.tanggalAkhirAcara"
                        placeholder="Tanggal Selesai Kegiatan"
                        disabled
                        isDetail
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col>
                    <b-form-group>
                      <label for="tempatAcara">Tempat Kegiatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="tempatAcara"
                        v-model="form.tempatAcara"
                        placeholder="Tempat Kegiatan"
                        disabled
                        isDetail
                        max-length="100"
                      />
                    </b-form-group>
                    <!-- Lokasi Dokumen -->
                    <b-row>
                      <b-col
                        class="align-self-center mt-2"
                        :class="
                          dataDokumenKs
                            ? 'cursor-pointer'
                            : 'cursor-not-allowed'
                        "
                        md="4"
                        v-b-tooltip="
                          dataDokumenKs
                            ? 'Lihat Dokumen'
                            : 'File tidak ditemukan'
                        "
                      >
                        <b-button
                          v-if="dataDokumenKs"
                          variant="primary"
                          @click="showDokumenKs"
                          ><i class="ri-search-line mr-1"></i>Lihat
                          Dokumen</b-button
                        >
                        <b-button v-else variant="default" disabled
                          ><i class="ri-search-line mr-1"></i>Lihat
                          Dokumen</b-button
                        >
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <!-- Validasi -->
                <!-- Button -->
                <b-row class="mt-5">
                  <b-button
                    class="btn-block rounded mx-1"
                    variant="secondary"
                    size="sm"
                    @click="$router.go(-1)"
                    ><i class="ri-arrow-left-line m-0"></i>Kembali</b-button
                  >
                </b-row>
              </b-form>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table -->
    </b-row>
  </b-container>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  name: 'kerjasama.ia.detail',
  computed: {
    ...mapState('authService', ['token'])
  },
  mounted() {
    if (!this.$route.params.idIa) this.$router.go(-1)
    this.form.idIa = this.$route.params.idIa
    this.innitData()
  },
  methods: {
    /**
     * Get data Detail Kerjasama MoU
     */
    async getDataTransaksiIa_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDataTransaksiIa?idIa=${this.form.idIa}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.dataIa = res.data[0]
          this.form = { ...this.dataIa }
          this.form.lokasiDokumenIa = null
          // Dokumen KS
          this.getDokumenKs_()
        })
        .catch(err => {
          this.$router.push({
            name: 'kerjasama.jenisDokumen'
          })
          this.$toast.error('Terjadi kesalahan pada sistem')
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * GET => Dokumen KS
     */
    async getDokumenKs_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDokumenIa?lokasiDokumenIa=${this.dataIa.lokasiDokumenIa}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.dataDokumenKs = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * SHOW => Dokumen IA
     */
    async showDokumenKs() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDokumenIa?lokasiDokumenIa=${this.dataIa.lokasiDokumenIa}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          var url = window.URL.createObjectURL(res.data)
          var a = document.createElement('a')
          a.href = url
          a.download = `Dokumen_IA${this.dataIa.kodeIa}_${this.dataIa.nomorSuratUt}.pdf`
          a.click()
          a.remove()
          setTimeout(() => window.URL.revokeObjectURL(url), 100)
        })
        .catch(err => {
          console.log('ERROR', err)
          this.$toast.error('File tidak ditemukan')
          throw new Error(err)
        })
      return result
    },
    /**
     * Init Data
     */
    async innitData() {
      /** Get data Detail Kerjasama IA */
      this.getDataTransaksiIa_()
    }
  },
  data() {
    return {
      // Others
      statusValidasi: null,
      dataDokumenKs: null,
      showCetakan: null,
      // Date Picker
      basicConfig: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d'
      },
      // List Data
      listInputMitra: [],
      // Form
      form: {
        idIa: null,
        nomorSuratUt: null,
        nomorSuratMitra: null,
        tanggalSurat: null,
        namaPihakSatu: null,
        jabatanPihakSatu: null,
        instansiPihakSatu: null,
        alamatPihakSatu: null,
        namaPihakDua: null,
        jabatanPihakDua: null,
        instansiPihakDua: null,
        alamatPihakDua: null,
        namaKegiatan: null,
        judulPks: null,
        tanggalAcara: null,
        tanggalAkhirAcara: null,
        tempatAcara: null,
        lokasiDokumenIa: null
      },
      // Data IA
      dataIa: {}
    }
  }
}
</script>
